// .wrapper{
//     min-height: 100vh;
// }

.sidebar{
	width: 430px;
	padding: 15px 35px;
    padding-bottom: 200px;
	position: relative;
    height: 100vh;
	min-height: 900px;
}

.top-link {
    a {
		display: block;
        font-size: 11px;
        font-weight: 500;
        color: #979797;
        cursor: pointer;
        img {
            width: 20px;
            height: 20px;
        }
    }
}

.step-title {
	margin-top: 20px;
    border-bottom: solid 1px $border;
    padding-bottom: 20px;

    span {
        font-size: 11px;
        font-weight: 500;
        display: block;
    }

    div {
        &.flex {
            align-items: flex-end;
        }

        h2 {
            font-size: $s28;
            font-weight: 500;
            color: $black;
            margin-top: 10px;
        }

        a {
			display: block;
            font-size: $s14;
            color: $gray2;
        }
    }
}

.text-content {
    margin-top: 35px;
h3{
    font-size: $s22;
    font-weight: 500;
    color: $black;
    margin-bottom: 17px;
}
    p {
        font-size: $s14;
        color: $black;
        margin-bottom: 20px;
    }
}

.connect-upload {
    margin: 30px 0;
}

.connect {
    img {
        margin-left: 10px;
    }
    a,
	span{
		display: block;
		line-height: 21px;
        color: $white;
        &.flex{
            display: flex;
        }
	}
}

.connect {
    background-color: $red;
    color: $white;
    font-weight: bold;
    font-size: $s16;  
    cursor: pointer;  
    &:hover {
        background-color: $red2;
    }
    &.disabled {
        pointer-events: none;
        background-color: $red2;
    }
}

.upload {
    background: $gray;
    margin-top: 20px;
    font-size: $s16;
    font-weight: 500;
    color: $black;
    &:hover {
        background-color:#d1d1d1 ;
    }
}

.connect,
.upload {
    padding: 15px;
}
.bottom-links{
        position: absolute;
        bottom: 0;
        left: 35px;
        right: 35px;
        background: $white;
        padding-bottom: 15px;
        .shadow{
            position: absolute;
            top: -50px;
        }
	.connect{
		border-bottom: 1px solid $border;
	}
    .next-step{
        position: relative;
        z-index: 10;
    }
	
	.trust-pilot{
        padding-top: 11px;
		margin-top: 15px;
	    border-top: 1px solid $border;
		
		span{
			display: block;
			font-size: $s12;
			line-height: 1;
			a{
				display: inline-block;
				text-decoration: underline !important;
				color: $black;
			}
			strong{
				font-weight: 500;
			}
		}
	}
}
.opacity-5{
	opacity: 0.5;
}

// Page 2
.select-dropdown{
    select{
        appearance: none;
        background-image: url("../../img/angle-down.svg");
        background-repeat: no-repeat;
        background-position: 96% 12px;
        width: 100%;
        padding: 12px 20.2px 12px 20px;
        border: solid 1px $border2;
        background-color: $gray3;
        font-size: $s14;
        color: $black;
    }
}
.map{
    &s{
        &-list{
            overflow-y: scroll;
            max-height: 550px;
            &.ui-sortable{
                height: auto;
                min-height: 350px;
               
              
            }
            margin-top: 30px;
            overflow-y: scroll;
            height: calc(100vh - 380px);
            min-height: 500px;
            padding-bottom: 30px;
            &::-webkit-scrollbar {
                width: 1px;
            }
            
            /* Track */
            &::-webkit-scrollbar-track {
                background: #fff;
            }
            
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #fff;
            }
            
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #fff;
            }
        }
       
    }

    &-single{
        border: 1px solid $border2;
        padding: 8px;
        margin-bottom: 10px;
    }
    &-left{
        padding-left: 10px;
        padding-top: 4px;
        padding-bottom: 4px;
        h3{
            font-size: $s15;
            color: $black;
            margin-bottom: 6px;
        }
        p{
            font-size: $s12;
            color: #666666;
            margin-bottom: 10px;
        }
        button{
                padding: 5px 16px 7px;
                border-radius: 2px;
                box-shadow: 0 2px 7px 0 #0000001d;
                border: solid 2px $border2;
                background-color: $white;
                cursor: pointer;
                font-weight: 500;
                color: $red2;
                font-size: $s15;
                svg{
                    margin-right: 3px;
                }
                &:hover{
                    background: $red2;
                    color: $white;
                    border-color: $red2;
                    #Upload---02{
                        stroke: #fff;
                    }
                }
                &.add{
                    &.added{
                        display: none;
                    }
                    display: flex;
                    align-items: center;
                }
                &.remove{
                    display: none;
                    &.added{
                        display: flex;
                        align-items: center;
                        &.added-list{
                            display: flex;
                        }
                    }
                    &.added-list{
                        display: flex;
                        align-items: center;
                    }
                
                }
        }
        span{}
    }
    &-right{}
}
.instead{
 a{
     padding: 20px 0;
     display: block;
     text-decoration: underline !important;
     font-size: $s14;
     color: $gray2;
 }   
}

// .mapboxgl-ctrl-logo {
//     display: none !important;
// }

.mapboxgl-ctrl{
    z-index: 0 !important;
    position: absolute;
    pointer-events: none;
}



.add-more{
    position: relative;
    z-index: 99;
    margin-top: -20px;
    background: $white;
    padding-top: 20px;
    p{
        margin-bottom: 10px;
        font-weight: 400;
        font-size: $s14;
    }
    a{
        display: inline-block;
        color: $black;
        text-decoration: underline !important;
    }
}

.theme-midnight {
    .rs-img {
        background: #000000;
    }
    .map-container {
        border-color: #000000;
    }
    .rs-print {
        background: #000000;
        border-color: #000000;
    }
    h1,h2,h3,h4 {
        color: #FFFFFF;
    }
}

.theme-dark-contours {
    .rs-img {
        background: #000000;
    }
    .map-container {
        border-color: #000000;
    }
    .rs-print {
        background: #000000;
        border-color: #000000;
    }
    h1,h2,h3,h4 {
        color: #FFFFFF;
    }

    
}

.theme-light-contours {
    .rs-img {
        background: #FFFFFF;
    }
    .map-container {
        border-color: #000000;
    }
    .rs-print {
        background: #FFFFFF;
        border-color: #000000;
    }
    h1,h2,h3,h4 {
        color: #000000;
    }
    &.right-sidebar .map-container .marker {
        background-image: url("../../img/endpoint/light_contours.svg");  
        color: #000000;
    }
}

.theme-blueprint {
    .rs-img {
        background: #415EAF;
    }
    .map-container {
        border-color: #FFFFFF;
    }
    .rs-print {
        background: #415EAF;
        border-color: #FFFFFF;
    }
    h1,h2,h3,h4 {
        color: #FFFFFF;
    }
    &.right-sidebar .map-container .marker {
        background-image: url("../../img/endpoint/blueprint.svg");  
        color: #FFFFFF
    }
}

.theme-tangerine {
    .rs-img {
        background: #E95E2A;
    }
    .map-container {
        border-color: #FFFFFF;
    }
    .rs-print {
        background: #E95E2A;
        border-color: #FFFFFF;
    }
    h1,h2,h3,h4 {
        color: #FFFFFF;
    }
    &.right-sidebar .map-container .marker {
        background-image: url("../../img/endpoint/tangerine.svg");  
        color: #FFFFFF
    }
}


.theme-satelite {
    .rs-img {
        background: #022129;
    }
    .map-container {
        border-color: #022129;
    }
    .rs-print {
        background: #022129;
        border-color: #022129;
    }
    h1,h2,h3,h4 {
        color: #FFFFFF;
    }
    &.right-sidebar .map-container .marker {
        background-image: url("../../img/endpoint/satalitte.svg");  
        color: #FFFFFF
    }
}

.theme-sepia {
    .rs-img {
        background: #FFECCB;
    }
    .map-container {
        border-color: #000000;
    }
    .rs-print {
        background: #FFECCB;
        border-color: #000000;
    }
    h1,h2,h3,h4 {
        color: #000000;
    }
    &.right-sidebar .map-container .marker {
        background-image: url("../../img/endpoint/sepia.svg");  
        color: #CB1517;
    }
}


.theme-old-world {
    .rs-img {
        background: #E2D9D3;
    }
    .map-container {
        border-color: #4E4137;
    }
    .rs-print {
        background: #E2D9D3;
        border-color: #4E4137;
    }
    h1,h2,h3,h4 {
        color: #211C17;
    }
    &.right-sidebar .map-container .marker {
        background-image: url("../../img/endpoint/old_world.svg");  
        color: #211C17
    }
}


.theme-outline {
    .rs-img {
        background: #000000;
    }
    .map-container {
        border-color: #000000;
    }
    .rs-print {
        background: #000000;
        border-color: #000000;
    }
    h1,h2,h3,h4 {
        color: #FFFFFF;
    }
}

.label-marker-input {
    display: none;
    &.visible {
        display: flex;
    }
    input {
        margin-right: 10px;
    }

    img {
        cursor: pointer;
    }
}