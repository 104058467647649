.label{
    &s{}
    &-single{
        margin-bottom: 12px;
        input{
            width: 100%;
            padding: 12px 82px 12px 20px;
            border: solid 1px  $border2;
            background-color: #f9f9f9;
            &:placeholder{
                  font-size: $s14;
                   color: $black;
            }
            &:focus {
                border-color: #333333;
                border-width: 2px;;
            }
        }
    }
}