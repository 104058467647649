@font-face {
    font-family: 'DIN';
    src: url('../../fonts/din.eot');
    src: url('../../fonts/din.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/din.woff2') format('woff2'),
        url('../../fonts/din.woff') format('woff'),
        url('../../fonts/din.ttf') format('truetype'),
        url('../../fonts/din.svg#dIN') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Alternate';
    src: url('../../fonts/dinalternate-bold.eot');
    src: url('../../fonts/dinalternate-bold.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/dinalternate-bold.woff2') format('woff2'),
        url('../../fonts/dinalternate-bold.woff') format('woff'),
        url('../../fonts/dinalternate-bold.ttf') format('truetype'),
        url('../../fonts/dinalternate-bold.svg#dinalternate-bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Medium';
    src: url('../../fonts/din-medium.eot');
    src: url('../../fonts/din-medium.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/din-medium.woff2') format('woff2'),
        url('../../fonts/din-medium.woff') format('woff'),
        url('../../fonts/din-medium.ttf') format('truetype'),
        url('../../fonts/din-medium.svg#din-medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
font-family: 'DIN Black';
    src: url('../../fonts/DIN-Black.eot');
    src: url('../../fonts/DIN-Black.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/DIN-Black.woff2') format('woff2'),
        url('../../fonts/DIN-Black.woff') format('woff'),
        url('../../fonts/DIN-Black.ttf') format('truetype'),
        url('../../fonts/DIN-Black.svg#DIN-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Bold';
        src: url('../../fonts/DIN-Bold.eot');
        src: url('../../fonts/DIN-Bold.eot?#iefix') format('embedded-opentype'),
            url('../../fonts/DIN-Bold.woff2') format('woff2'),
            url('../../fonts/DIN-Bold.woff') format('woff'),
            url('../../fonts/DIN-Bold.ttf') format('truetype'),
            url('../../fonts/DIN-Bold.svg#DIN-Bold') format('svg');
        font-weight: 900;
        font-style: normal;
        font-display: swap;
    }

