
.accordion {
    list-style: none;
    padding: 0;
    border-bottom: solid 1px $border;
    margin-top: -.5px;
    overflow-y: scroll;
    height: 100vh;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    .inner {
        overflow: hidden;
        display: none;
        &.show {
          display: block;
        }

        select {
            width: 99%;
            padding: 8px 32px 8px 16px;
            margin-top: 8px;
            margin-bottom: 16px;
            border: solid 1px rgba(221, 221, 221, 0.8666666667);
            background-color: #f9f9f9;
        }
    }
    &>li{
        &.active{
            background-image: url("../../img/acordion-active.svg");    
        }
        background-image: url("../../img/acordion-inactive.svg");
        background-size: 16px 16px;
        background-repeat: no-repeat;
        background-position: 100% 12px;
        .inner{
            padding-bottom: 15px;
            .labels {
                overflow-y: scroll;
                max-height: 250px;
            }
        }
    }
  
    li {
       
        a.toggle {
            width: 100%;
            display: flex;
            justify-content: space-between;
            color: $black;
            padding: 13px 0;
            transition: background .3s ease;
            border-top: solid 1px $border;          
            font-weight: 500;
        }
    }
}

.accordion-list {
    overflow: hidden;
}

.accordion::-webkit-scrollbar {
    display: none;
}
  