.data{
    &-input{
        margin: 10px 0;
        input{
        width: 100%;
        font-size: 11px;
        height: 40px;
        padding: 14px 9px 14px 20px;
        border: solid 1px $border2;
        background-color: $gray3;
        }
    }
}