@media only screen and (max-width: 991px) {
    .sidebar{
        width: 280px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .right-sidebar{
        width: calc(100% - 280px);

        .rs-img {
            &.canvas-size-null {
                height: 750px;
               min-width: 600px;
                max-width: 600px;
                .map-container {
                    height: 528px
                }
            }
            &.canvas-size-8x12 {
                height: 750px;
                min-width: 500px;
                max-width: 500px;
                .map-container {
                    height: 467px;
                }
            }
            &.canvas-size-12x16 {
                height: 750px;
                min-width: 562px;
                max-width: 562px;
                .map-container {
                    height: 528px;
                }
            }
            &.canvas-size-16x20 {
                height: 750px;
                min-width: 600px;
                max-width: 600px;
                .map-container {
                    height: 528px
                }
            }
        }
    }
    .map-order-list{
        padding-left: 25px;
    }
    .map-single:before{
        left: -25px;
    }
    .bottom-links {
        left: 15px;
        right: 15px;
    }

    .connect, .upload {
        padding: 10px;
        font-size: 12px;
    }

    .style-left > div {
        width: 24px;
        height: 24px;
    }
}
@media only screen and (max-width: 767px) {
    .wrapper{
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
    .right-sidebar{
        display: none;
        height: auto;
        min-height: auto;
        .rs-img{
            height: auto;
            min-height: auto;
            padding: 25px 0;
            img{
                height: auto;
                max-height: 100%;
            }
        }
    }
    .sidebar{
        width: 100%;
        height: auto;
        min-height: auto;
    }
    .right-sidebar{
        width: 100%;

        .rs-img {
            &.canvas-size-null {
                height: 375;
                width: 300;
                .map-container {
                    height: 1280px;
                }
                canvas {
                    width: 300px !important
                }
            }
            &.canvas-size-8x12 {
                height: 375;
                width: 300;
                .map-container {
                    height: 1280px;
                }
                canvas {
                    width: 300px !important
                }
            }
            &.canvas-size-12x16 {
                height: 300;
                width: 225;
                .map-container {
                    height: 997px;
                }
                canvas {
                    width: 22px !important
                }
            }
            &.canvas-size-16x20 {
                height: 375;
                width: 300;
                .map-container {
                    height: 1280px;
                }
                canvas {
                    width: 300px !important
                }
            }
        }
    }
    .bottom-links{
        position: unset;
        bottom: unset;
        left: unset;
        right: unset;
        position: relative;
        .shadow {
            position: absolute;
            top: -50px;
        }
    }
 .accordion-list{
     margin-bottom: 50px;
 }
   
}
@media only screen and (max-width: 575px) {}
@media only screen and (max-width: 379px) {}
@media only screen and (max-width: 349px) {}