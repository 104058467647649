.map{
    &-order-list{
        padding-left: 38px;
    
    }
    &-single{
        position: relative;
        &::before{
            position: absolute;
            content: "";
            width: 14px;
            height: 17px;
            background-image: url("../../img/menu.svg");
            background-repeat: no-repeat;

            left: -40px;
            top: 50%;
            margin-top: -8px;

        }
    }
}