.popup-overlay {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    z-index: 100;
}
.popup-main {
    margin: 70px auto;
    border-radius: 5px;
    width: 70%;
    position: relative;
    transition: all 5s ease-in-out;
}
.size-examples {
    background: #f8e9af;
    padding: 15px 20px;
    display: block;
    width: 100%;
    cursor: pointer;
    font-family: "DIN";
    margin: 10px 0;
    font-size: 16px;
    span {
        margin: 5px 0 0 0;
        display: block;
        text-decoration: underline;
    }
}


.popup-main h2 {
    margin-top: 0;
    color: #333;
    font-family: Tahoma, Arial, sans-serif;
}
.popup-main .popup-close {
    position: absolute;
    top: -25px;
    right: -25px;
    transition: all 200ms;
    display: block;
    width: 50px;
    height: 50px;
    background: #fff;
    text-align: center;
    font-size: 36px;
    border-radius: 100px;
    border-radius: 100px;
    line-height: initial;
}
.popup-close:hover {
cursor: pointer;
    color: #000;
}
.popup-content {
    background: #fff;
    max-height: 30%;
    overflow: auto;
    p {
        font-family: "DIN-Black";
        margin: 30px 0;
        padding: 0 20px;

        a{
            color: #000;
            text-decoration: underline;
        }
    }
}

@media screen and (max-width: 700px) {
    .popup-main {
        width: 70%;
    }
}