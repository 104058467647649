$dark: #3A3A3A;
$blue: #159ee6;
$white: #fff;
$black: #000;
$red: #F05523;
$red2: #d93708;
$gray: #ececec;
$gray3: #f9f9f9;
$gray4: #bdbdbd;
$gray2: #757575;
$border: #dcdcdc;
$border2: #dddd;

/* sizes*/
$s12: 12px;
$s14: 14px;
$s15: 15px;
$s16: 16px;
$s18: 18px;
$s22: 22px;
$s28: 28px;

/* fonts*/
$pf: 'DIN';
$sf: 'DIN Alternate';