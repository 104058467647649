
.preview-mode {
	background-color: $gray;
	padding: 14px 15px 14px 18px !important;
	span {
		font-size: $s14;
		color: $black;
		font-weight: 500;
	}
	margin-bottom: 12px;
}
.my-radio {
	align-items: center;
	padding: 10px 0;
	span {
		font-size: $s14;
		color: $black;
	}
	input {
		&:checked {
			+ {
				.radio-slider {
					background-color: $blue;
					&:before {
						-webkit-transform: translateX(16px);
						-ms-transform: translateX(16px);
						transform: translateX(16px);
					}
				}
			}
		}
		&:focus {
			+ {
				.radio-slider {
					box-shadow: 0 0 1px $blue;
				}
			}
		}
	}
}
.radio-switch {
	position: relative;
	display: inline-block;
	width: 46px;
	height: 27px;
	input {
		opacity: 0;
		width: 0;
		height: 0;
	}
}
.radio-slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: $gray4;
	-webkit-transition: .4s;
	transition: .4s;
	&:before {
		position: absolute;
		content: "";
		height: 24px;
		width: 24px;
		left: 3px;
		bottom: 2px;
		background-color: $white;
		-webkit-transition: .4s;
		transition: .4s;
	}
}
.radio-slider.round {
	border-radius: 34px;
	&:before {
		border-radius: 50%;
	}
}
.custom-radio {
	display: flex;
	flex-wrap: wrap;
}
.single-radio {
	padding: 9px 0;
	width: 50%;
	display: inline-block;
	label {
		font-size: 14px;
		color: $black;
		cursor: pointer;
	}
}
.radio {
	input[type=radio] {
		position: absolute;
		opacity: 0;
		+ {
			.radio-label {
				&:before {
					content: "";
					background: $white;
					border-radius: 100%;
					border: 2px solid $border2;
					display: inline-block;
					width: 16px;
					height: 16px;
					position: relative;
					top: 0px;
					margin-right: 12px;
					vertical-align: top;
					cursor: pointer;
					text-align: center;
					transition: all 250ms ease;
				}
			}
		}
		&:checked {
			+ {
				.radio-label {
					&:before {
						border: 2px solid $blue;
						width: 16px;
						height: 16px;
						background-color: #159ee6;
						box-shadow: inset 0 0 0 4px #f4f4f4;
					}
				}
			}
		}
		&:focus {
			+ {
				.radio-label {
					&:before {
						border: 2px solid $blue;
						width: 16px;
						height: 16px;
						outline: none;
						border-color: $blue;
					}
				}
			}
		}
	}
}
