/*===========================================================
 Common CSS
============================================================*/
/*===========================================================
Preloader CSS
============================================================*/
@-webkit-keyframes "spin" {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
@keyframes "spin" {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
.app-inner {
	font-size: $s14;
	font-family: $pf;
	font-weight: 400;
	color: $black;
	background: #fff;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	overflow-y: hidden;
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	&:active {
		outline: none;
		border: 0;
	}
	&:focus {
		outline: none;
		border: 0;
	}
}
ul {
	padding: 0;
	margin: 0;
}
li {
	transition: all .3s ease;
	&:hover {
		transition: all .3s ease;
	}
}
a {
	&:hover {
		transition: all .3s ease;
		text-decoration: none !important;
	}
	text-decoration: none !important;
}
.btn {
	transition: all .3s ease;
	&:hover {
		transition: all .3s ease;
	}
}
img {
	max-width: 100%;
}
strong {
	font-weight: 700;
}
u {
	border-bottom: 1px solid #fff;
	line-height: 1;
	text-decoration: unset;
}
.cboth {
	overflow: hidden;
	clear: both;
}
.left {
	float: left;
}
.right {
	float: right;
}
button {
	background-color: transparent;
	border: 0;
	&:active {
		outline: 0;
		outline: 0;
	}
	&:focus {
		outline: 0;
		outline: 0;
	}
}
h1 {
	margin: 0;
}
h2 {
	margin: 0;
}
h3 {
	margin: 0;
}
h4 {
	margin: 0;
}
h5 {
	margin: 0;
}
h6 {
	margin: 0;
}
p {
	margin: 0;
}
.preloader {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background: white;
	z-index: 99999999;
}
#preloader-logo {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}
.loading-msg {
	width: 100%;
	font-size: 0.75em;
	color: #555;
	position: fixed;
	bottom: 10%;
	left: 50%;
	transform: translate(-50%, 50%);
	text-align: center;
}
.spinner {
	width: 80px;
	height: 80px;
	border: 2px solid #f3f3f3;
	border-top: 3px solid #2489CE;
	border-radius: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	-webkit-animation: spin 1s infinite ease;
	animation: spin 1s infinite ease;
}
#loading-msg {
	width: 100%;
	position: absolute;
	left: 0;
	bottom: 25px;
	text-align: center;
	color: #333;
	font-size: 0.8em;
}
