.flex {
    display: flex;
}
.collumn{
    flex-direction: column;
    &>div{
        width: 100%;
    }
}

.acenter {
    align-items: center;
}

.jcenter {
    justify-content: center;
}

.space-between {
    justify-content: space-between;
}
.wrap{
    flex-wrap: wrap;
}
.wrapper {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
}
body{
    &.added{
        .connect.opacity-5{
            opacity: 1;
            transition: .3s;
            -webkit-transition: .3s;
            -moz-transition: .3s;
            -ms-transition: .3s;
            -o-transition: .3s;
        }
    }
}
