.pl{
    &-text{
        margin: 25px 0;
    }
    &-left,
    &-right{
        width: 47%;
        text-align: center;
        background: $gray;
        padding: 20px;
    }
    &-left{
        position: relative;
        button{
            position: absolute;
            display: inline-block;
            cursor: pointer;
        }
        .l{
            top: 50%;
            left: 10px;
            margin-top: -30px;
        }
        .t{
            top: 10px;
            left: 50%;
            margin-left: -30px;
        }
        .b{
            bottom: 10px;
            left: 50%;
            margin-left: -30px;
        }
        .r{
            right: 10px;
            top: 50%;
            margin-top: -30px;
        }
    }
    &-right{
        button{
            cursor: pointer;
        }
    }
    &-control{
        margin-bottom: 15px;
    }
}