.loader-overlay {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    z-index: 100;
}
.loader-main {
    margin: 70px auto;
    border-radius: 5px;
    width: 70%;
    height: 70%;
    position: relative;
    transition: all 5s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader-img {
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

@media screen and (max-width: 700px) {
    .popup-main {
        width: 70%;
    }
}