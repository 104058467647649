.style{
    &s{
        flex-wrap: wrap;
        .single-style{
            margin-bottom: 18px;
            width: 50%;
            h3{
                font-size: $s14;
                color: $black;
                font-weight: 400;
                
            }
        }
    }
 
    &-left{
        &>div{
            width: 54px;
            height: 54px;
            border-radius: 50%;
        }
    }
    &-right{
        padding-left: 10px;
        span,
        a{
            display: block;
            text-decoration: underline;
            font-size: 11px;
            color: $black;
        }
    }
    &-1{
        box-shadow: 0 2px 7px 0 #0000001d;
        border: solid 3px $blue;
        background-color: $white;
        overflow: hidden;
    }
    &-2,
    &-3,
    &-4,
    &-5,
    &-6{
        border: solid 1px #979797;
        background-color: $border2;
        overflow: hidden;
    }
}
