.right-sidebar {
	background: #E2D9D3;
	padding: 35px;
	text-align: center;
	width: calc(100% - 430px);
	display: flex;
	align-items: start;
	justify-content: center;
	box-sizing: content-box;
	&.theme-old-world {
		background: #8e8e8e;
	}
	.rs-img {
		height: 900px; 
		min-width: 720px;
		padding: 20px;
		background-color: white;
		position: relative;
		&.canvas-size-null {
			height: 900px; 
			min-width: 600px;
			max-width: 600px;
			.map-container {
				height: 677px;
			}
			// canvas {
			// 	width: 600px !important;
			// }
		}
		&.canvas-size-8x12 {
			height: 900px; 
			min-width: 600px;
			max-width: 600px;
			.map-container {
				height: 677px;
			}
			// canvas {
			// 	width: 600px !important;
			// }
		}
		&.canvas-size-12x16 {
			height: 900px;
			min-width: 675px;
			max-width: 675px;
			.map-container {
				height: 677px;
			}
			// canvas {
			// 	width: 675px !important;
			// }
		}
		&.canvas-size-16x20 {
			height: 900px; 
			min-width: 720px;
			max-width: 720px;
			.map-container {
				height: 677px;
			}
			// canvas {
			// 	width: 720px !important;
			// }
		}
	}
	.mapboxgl-map {
		max-width: 100%;
		max-height: 100%;
	}
	.map-container {
		border: 3px solid $black;
		border-bottom: none;
		overflow: hidden;
		.marker {
			background-image: url("../../img/endpoint/dark_contours_and_midnight_and_outlines.svg");  
			background-repeat: no-repeat;
			padding-left: 25px; 
			color: #FFFFFF;
			font-family: "DIN Bold";
			height: 20px;
			&.no-icon {
				background-image: none !important;
			}
			&.hidden {
				display: none;
			}
		}
	}
	.branding {
		position: absolute;
		width: 50px;
		top: 37px;
		right: 37px;
		z-index: 2;
	}
	.rs-print {
		padding-top: 30px;
		border: 3px solid $black;
		position: relative;
		h1 {
			text-transform: uppercase;
			font-size: 48px;
			font-weight: 600;
			line-height: 60px;
			font-family: "DIN Black";
		}

		h2 {
			text-transform: uppercase;
			font-size: 14px;
			font-family: "DIN Bold";
		}

		h3 {
			text-transform: uppercase;
			font-family: "DIN Bold";
			font-size: 14px;
			letter-spacing: 2px;
		}

		h4 {
			text-transform: uppercase;
			font-weight: 300;
			font-size: 9px;
		}

		&.medium-title-text {
			h1 {
				text-transform: uppercase;
				font-size: 32px;
				font-weight: 600;
				font-family: "DIN Black";
			}
		}

		&.long-title-text {
			h1 {
				text-transform: uppercase;
				font-size: 24px;
				font-weight: 600;
				font-family: "DIN Black";
			}
		}
		&.exporting {
			// padding-top: 40px;
		}
	}

	.rs-print-bottom {
		padding: 30px 20px 10px 20px;
		display: flex;
		justify-content: space-between;
	}

	.elevation-profile-wrapper {
		position: absolute;
		left: -3px;
		top: -100px;
		width: 101%;
		padding: 0;
		overflow: hidden;
		height: 100px;
		&.hidden {
			display: none;
		}
		canvas {
			position:absolute;
			bottom: 0;
		}
	}

}